import logo from './logo.svg';
import './App.css';
import Header from './Component/Header/Header';
import Home from './Component/HomePage/Home';
import ScreenLoader from './Component/ScreenLoader';
import { useEffect, useState, Suspense } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { BrowserRouter as Router } from 'react-router-dom';
import AppRoutes from './Routes/Routes';


function App() {

  const [loader, setLoad] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setLoad(false)
    }, 2000);
  }, [])




  if (loader) {
    return <ScreenLoader />
  }


  return (
    <div className="knsl-app">

      <Suspense fallback={< ScreenLoader />}>
        <Router>
          {/* {loader == "0" ? <ScreenLoader/> : */}  <AppRoutes />  {/* } */}
        </Router>

      </Suspense>




    </div>
  );
}

export default App;
