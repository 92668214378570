import React, { useEffect, useMemo, useState } from "react";
import { db } from "../../Firebase";
import firebase from "firebase";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { Link } from "react-router-dom";
import ScrollToTop from "../ScrollToTop/ScrollToTop";

function Gallery() {
  const [comments, setComments] = useState([]);
  const [filterByC, setfilterByC] = useState("All");

  useEffect(() => {
    const unsub = db
      .collection("gallery")
      .orderBy("createdAt", "desc")
      .onSnapshot((snapshot) => {
        const oderdetails = snapshot.docs.map((e) => {
          const data = e.data();
          data.id = e.id;
          return data;
        });
        setComments(oderdetails);
      });

    return () => {
      unsub();
    };
  }, []);

  const commentsData = useMemo(() => {
    let computedComments = comments;
    if (filterByC == "All") {
      return (computedComments = comments);
    } else {
      return (computedComments = comments?.filter((data) => {
        return data.category == filterByC;
      }));
    }

    return computedComments;
  }, [comments, filterByC]);

  console.log(commentsData);

  return (
    <div>
      <ScrollToTop />
      <Header />
      <section class="knsl-banner-simple cpm-180">
        <img src="img/palm.svg" class="knsl-deco-left" alt="palm" />
        <img src="img/palm.svg" class="knsl-deco-right" alt="palm" />
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="knsl-center knsl-title-frame">
                <h1 class="knsl-mb-20 knsl-h1-inner">Photo Gallery</h1>
                {/* <p class="knsl-mb-30">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p> */}
                <ul class="knsl-breadcrumbs">
                  <li>
                    <Link to={"/"}>Home</Link>
                    {/* <a href="index.html">Home</a> */}
                  </li>
                  <li>
                    <span>Gallery</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div class="knsl-p-100-100">
        <div class="container">
          <div class="knsl-filter knsl-mb-60 container  categoryImage">
            <a
              data-filter="*"
              class={
                filterByC == "All"
                  ? "knsl-work-category knsl-current"
                  : "knsl-work-category"
              }
              onClick={() => {
                setfilterByC("All");
              }}
            >
              All Photos
            </a>

            <a
              data-filter=".hotel"
              class={
                filterByC == "rooms"
                  ? "knsl-work-category knsl-current"
                  : "knsl-work-category"
              }
              onClick={() => {
                setfilterByC("rooms");
              }}
            >
              Rooms
            </a>
            <a
              data-filter=".restaurant"
              class={
                filterByC == "restaurant"
                  ? "knsl-work-category knsl-current"
                  : "knsl-work-category"
              }
              onClick={() => {
                setfilterByC("restaurant");
              }}
            >
              Restaurant
            </a>
            <a
              data-filter=".beach"
              class={
                filterByC == "valetParking"
                  ? "knsl-work-category knsl-current"
                  : "knsl-work-category"
              }
              onClick={() => {
                setfilterByC("valetParking");
              }}
            >
              Valet Parking
            </a>
            <a
              data-filter=".restaurant"
              class={
                filterByC == "swimingPool"
                  ? "knsl-work-category knsl-current"
                  : "knsl-work-category"
              }
              onClick={() => {
                setfilterByC("swimingPool");
              }}
            >
              Swiming Pool
            </a>
            <a
              data-filter=".restaurant"
              class={
                filterByC == "boardroom"
                  ? "knsl-work-category knsl-current"
                  : "knsl-work-category"
              }
              onClick={() => {
                setfilterByC("boardroom");
              }}
            >
              Boardroom
            </a>
            <a
              data-filter=".restaurant"
              class={
                filterByC == "confrenceHall"
                  ? "knsl-work-category knsl-current"
                  : "knsl-work-category"
              }
              onClick={() => {
                setfilterByC("confrenceHall");
              }}
            >
              Confrence Hall
            </a>
            <a
              data-filter=".restaurant"
              class={
                filterByC == "bannquet"
                  ? "knsl-work-category knsl-current"
                  : "knsl-work-category"
              }
              onClick={() => {
                setfilterByC("bannquet");
              }}
            >
              Bannquet
            </a>
            <a
              data-filter=".restaurant"
              class={
                filterByC == "bathroom"
                  ? "knsl-work-category knsl-current"
                  : "knsl-work-category"
              }
              onClick={() => {
                setfilterByC("bathroom");
              }}
            >
              Bathroom
            </a>
            <a
              data-filter=".restaurant"
              class={
                filterByC == "rooftop"
                  ? "knsl-work-category knsl-current"
                  : "knsl-work-category"
              }
              onClick={() => {
                setfilterByC("rooftop");
              }}
            >
              Roof Top
            </a>
          </div>

          <div class="knsl-masonry-grid knsl-3-col">
            <div class="knsl-grid-sizer"></div>

            {commentsData &&
              commentsData?.map((data, i) => {
                return (
                  <div class="knsl-masonry-grid-item knsl-masonry-grid-item-33 cmGallery  restaurant">
                    <div className="cmGallery1">
                      <a
                        data-fancybox="gallery"
                        href={data?.image}
                        class="knsl-gallery-item"
                      >
                        <img src={data?.image} alt="image" />
                        <span class="knsl-zoom">
                          <i class="fas fa-search-plus"></i>
                        </span>
                      </a>
                    </div>

                    {/* <!-- gallery item end --> */}
                  </div>
                );
              })}

            {/* <div class="knsl-masonry-grid-item knsl-masonry-grid-item-33   restaurant">

                            
                            <a data-fancybox="gallery" href="img/about/1.jpg" class="knsl-gallery-item">
                                <img src="img/about/1.jpg" alt="image" />
                                    <span class="knsl-zoom"><i class="fas fa-search-plus"></i></span>
                            </a>
                            

                        </div>



                        <div class="knsl-masonry-grid-item knsl-masonry-grid-item-33 beach hotel">

                            
                            <a data-fancybox="gallery" href="img/about/v1.jpg" class="knsl-gallery-item">
                                <img src="img/about/v1.jpg" alt="image" />
                                    <span class="knsl-zoom"><i class="fas fa-search-plus"></i></span>
                            </a>
                            

                        </div>
                        <div class="knsl-masonry-grid-item knsl-masonry-grid-item-33 restaurant">

                            
                            <a data-fancybox="gallery" href="img/about/2.jpg" class="knsl-gallery-item">
                                <img src="img/about/2.jpg" alt="image" />
                                    <span class="knsl-zoom"><i class="fas fa-search-plus"></i></span>
                            </a>
                            

                        </div>
                        <div class="knsl-masonry-grid-item knsl-masonry-grid-item-33 hotel beach">

                            
                            <a data-fancybox="gallery" href="img/about/v2.jpg" class="knsl-gallery-item knsl-scroll-animation">
                                <img src="img/about/v2.jpg" alt="image" />
                                    <span class="knsl-zoom"><i class="fas fa-search-plus"></i></span>
                            </a>
                            

                        </div>
                        <div class="knsl-masonry-grid-item knsl-masonry-grid-item-33 beach">

                            
                            <a data-fancybox="gallery" href="img/about/3.jpg" class="knsl-gallery-item knsl-scroll-animation">
                                <img src="img/about/3.jpg" alt="image" />
                                    <span class="knsl-zoom"><i class="fas fa-search-plus"></i></span>
                            </a>
                            

                        </div>
                        <div class="knsl-masonry-grid-item knsl-masonry-grid-item-33 restaurant hotel">

                            
                            <a data-fancybox="gallery" href="img/about/v3.jpg" class="knsl-gallery-item knsl-scroll-animation">
                                <img src="img/about/v3.jpg" alt="image" />
                                    <span class="knsl-zoom"><i class="fas fa-search-plus"></i></span>
                            </a>
                            

                        </div>
                        <div class="knsl-masonry-grid-item knsl-masonry-grid-item-33 hotel restaurant">

                            
                            <a data-fancybox="gallery" href="img/about/4.jpg" class="knsl-gallery-item knsl-scroll-animation">
                                <img src="img/about/4.jpg" alt="image" />
                                    <span class="knsl-zoom"><i class="fas fa-search-plus"></i></span>
                            </a>
                            

                        </div>
                        <div class="knsl-masonry-grid-item knsl-masonry-grid-item-33 restaurant">

                            
                            <a data-fancybox="gallery" href="img/about/7.jpg" class="knsl-gallery-item knsl-scroll-animation">
                                <img src="img/about/7.jpg" alt="image" />
                                    <span class="knsl-zoom"><i class="fas fa-search-plus"></i></span>
                            </a>
                            

                        </div>
                        <div class="knsl-masonry-grid-item knsl-masonry-grid-item-33 beach">

                            
                            <a data-fancybox="gallery" href="img/about/v4.jpg" class="knsl-gallery-item knsl-scroll-animation">
                                <img src="img/about/v4.jpg" alt="image" />
                                    <span class="knsl-zoom"><i class="fas fa-search-plus"></i></span>
                            </a>
                            

                        </div>
                        <div class="knsl-masonry-grid-item knsl-masonry-grid-item-33 restaurant hotel">

                            
                            <a data-fancybox="gallery" href="img/about/5.jpg" class="knsl-gallery-item knsl-scroll-animation">
                                <img src="img/about/5.jpg" alt="image" />
                                    <span class="knsl-zoom"><i class="fas fa-search-plus"></i></span>
                            </a>
                            

                        </div>
                        <div class="knsl-masonry-grid-item knsl-masonry-grid-item-33 hotel">

                            
                            <a data-fancybox="gallery" href="img/about/v5.jpg" class="knsl-gallery-item knsl-scroll-animation">
                                <img src="img/about/v5.jpg" alt="image" />
                                    <span class="knsl-zoom"><i class="fas fa-search-plus"></i></span>
                            </a>
                            

                        </div>
                        <div class="knsl-masonry-grid-item knsl-masonry-grid-item-33 hotel">

                            
                            <a data-fancybox="gallery" href="img/about/6.jpg" class="knsl-gallery-item knsl-scroll-animation">
                                <img src="img/about/6.jpg" alt="image" />
                                    <span class="knsl-zoom"><i class="fas fa-search-plus"></i></span>
                            </a>
                            

                        </div>
                        <div class="knsl-masonry-grid-item knsl-masonry-grid-item-33 restaurant">

                            
                            <a data-fancybox="gallery" href="img/about/8.jpg" class="knsl-gallery-item knsl-scroll-animation">
                                <img src="img/about/8.jpg" alt="image" />
                                    <span class="knsl-zoom"><i class="fas fa-search-plus"></i></span>
                            </a>
                            

                        </div> */}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Gallery;
