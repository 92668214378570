import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <div>
          <footer>
      <div className="knsl-footer">
        <div className="container knsl-p-100-100">
          <div className="row">
            <div className="col-md-6 col-lg-4">
              <img className="knsl-footer-logo" src="img/png.png" alt="Kinsley" />
              <div className="knsl-footer-about">Lorem ipsum dolor sit amet, conser adipiscing elit. In consectetur tincidunt dolor.
              Lorem ipsum dolor sit amet, conser adipiscing elit. In consectetur tincidunt dolor.</div>
              {/* <div className="knsl-footer-social">
                <a href="#"><i className="fab fa-twitter"></i></a>
                <a href="#"><i className="fab fa-facebook-f"></i></a>
                
                <a href="#"><i className="fab fa-instagram"></i></a>
                
              </div> */}
            </div>
            <div className="col-md-6 col-lg-2">
              <h4>Menu</h4>
              <ul className="knsl-footer-menu">
                <li><a href="#">Home</a></li>
                <li><a href="#about">About us</a></li>
                <li><a href="#rooms">Rooms</a></li>
                <li><a href="#ourservices">Our services</a></li>
                <li><a href="#contectinfo">Contact info</a></li>
              </ul>
            </div>
            <div className="col-md-6 col-lg-3">
              <h4>Quick Links</h4>
              <ul className="knsl-footer-menu">


                <li>

              
                <Link to={"/terms-and-conditions"}>Terms and conditions</Link>
                  {/* <a href="#.">Terms and conditions</a> */}
                  </li>


                <li>

                  <Link to={"/privacy-policy"}>Privacy policy</Link>
                  {/* <a  href="#.">Privacy policy</a> */}
                  </li>
                <li><a target={"_blank"} href="https://wa.me/919983343200">Help center</a></li>
                {/* <li><a href="#.">Work with us</a></li> */}
                {/* <li><a href="#.">Sitemap</a></li> */}
              </ul>
            </div>
            <div className="col-md-6 col-lg-3">
              <h4>
                {/* Instagram */}
                <div className="knsl-footer-social">
                <a href="#"><i className="fab fa-twitter"></i></a>
                <a href="#"><i className="fab fa-facebook-f"></i></a>
                
                <a href="#"><i className="fab fa-instagram"></i></a>
                
              </div>
              </h4>
              
              <div className="footer-insta">
                <a href="#"><img src="img/about/1.webp" alt="Image" /></a>
                <a href="#"><img src="img/about/2.webp" alt="Image" /></a>
                <a href="#"><img src="img/about/3.webp" alt="Image" /></a>
                <a href="#"><img src="img/about/4.webp" alt="Image" /></a>
                <a href="#"><img src="img/about/5.webp" alt="Image" /></a>
                <a href="#"><img src="img/about/6.webp" alt="Image" /></a>
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <div className="container">
            <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-4">
                <div className="">
              <div style={{color: "#fff"}}>&copy; Hotel SD Prime 2021. All Rights Reserved.</div>
            </div>
              </div>
              <div className="col-md-4"></div>
            </div>
            
            
          </div>
        </div>
      </div>
    </footer>
    </div>
  )
}

export default Footer