import React from 'react'

function ScreenLoader() {
  return (
    <div>
         <div className="knsl-preloader-frame">
      <div className="knsl-preloader">
        <img src="img/png.png" alt="HOTEL SD PRIME" />
        <div className="knsl-preloader-progress">
          <div className="knsl-preloader-bar"></div>
        </div>
        <div><span className="knsl-preloader-number" data-count="101">0</span>%</div>
      </div>
    </div>
    </div>
  )
}

export default ScreenLoader