import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import BookRoom from '../Component/BookRoom/BookRoom';
import Gallery from '../Component/Gallery/Gallery';
import Home from '../Component/HomePage/Home';
import PrivacyPolicy from '../Component/PrivacyPolicy/PrivacyPolicy';
import ScreenLoader from '../Component/ScreenLoader';
import TermsConditions from '../Component/TermsConditions/TermsConditions';



function AppRoutes() {


  return (
    <div>

        <Suspense fallback={<ScreenLoader/>}>
          <Switch>
            <Route exact path="/" component={Home} />


            <Route exact path="/book-room" component={BookRoom} />
            <Route exact path="/privacy-policy" component={PrivacyPolicy} />
            <Route exact path="/terms-and-conditions" component={TermsConditions} />
            <Route exact path="/gallery" component={Gallery} />
            
          </Switch>
        </Suspense>

    </div>
  )
}

export default AppRoutes
