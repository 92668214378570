import React from 'react'

function Features() {
    return (
   
            <section id="knsl-triger-1">

                <div className="container">

                    {/* <!-- features card --> */}
                    <div className="knsl-features-card" style={{marginTop: "-80px"}}>

                        <div className="row">
                            <div className="col-6 col-lg-3">

                                {/* <!-- icon box --> */}
                                <div className="knsl-icon-box">
                                    <img src="img/features/1.svg" alt="icon" className="knsl-mb-10" />
                                    <h5>Airport transfer</h5>
                                </div>
                                {/* <!-- icon box end --> */}

                            </div>
                            <div className="col-6 col-lg-3">

                                {/* <!-- icon box --> */}
                                <div className="knsl-icon-box">
                                    <img src="img/features/2.svg" alt="icon" className="knsl-mb-10" />
                                        <h5>All inclusive</h5>
                                </div>
                                {/* <!-- icon box end --> */}

                            </div>
                            <div className="col-6 col-lg-3">

                                {/* <!-- icon box --> */}
                                <div className="knsl-icon-box">
                                    <img src="img/features/3.svg" alt="icon" className="knsl-mb-10" />
                                    <h5>Air-conditioned</h5>
                                </div>
                                {/* <!-- icon box end --> */}

                            </div>
                            <div className="col-6 col-lg-3">

                                {/* <!-- icon box --> */}
                                <div className="knsl-icon-box">
                                    <img src="img/features/4.svg" alt="icon" className="knsl-mb-10" />
                                        <h5>Under protection</h5>
                                </div>
                                {/* <!-- icon box end --> */}

                            </div>
                        </div>

                    </div>
                    {/* <!-- features card end --> */}

                </div>
            </section>
     
    )
}

export default Features