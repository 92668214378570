import React, { useEffect, useState } from 'react'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import ScreenLoader from '../ScreenLoader'
import ScrollToTop from '../ScrollToTop/ScrollToTop'
import Banner from './Banner'
import BestRoom from './BestRoom'
import Counter from './Counter'
import Features from './Features'
import HomeAbout from './HomeAbout'
import HomeContact from './HomeContact'
import HomeServices from './HomeServices'

function Home() {

    const [Loader, setLoader] = useState("0")

    useEffect(() => {
     setTimeout(() => {
      setLoader("1")
     }, 2000);
    
    
    }, [])

  return (
    <div>
        {/* {
  Loader == "1" ?  */}
         <div className="knsl-app">
         <ScrollToTop/>
        <Header/>
        <Banner/>
        <Features/>
        <HomeAbout/>
        <Counter/>
        <BestRoom/>
        <HomeServices/>
        <HomeContact/>
        <Footer/>
        </div>
        {/* :  <ScreenLoader/> 
    } */}
    </div>
  )
}

export default Home