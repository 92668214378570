import React from 'react'

function HomeServices() {
  return (
    <div id='ourservices'>
        <section className="knsl-p-100-80">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">

            <div className="knsl-center knsl-title-frame knsl-scroll-animation knsl-mb-100">
              <h2 className="knsl-mb-20">HOTEL FACILITES</h2>
              <p className="knsl-mb-30">
              Sd prime is your best place for a business stay as we offer the best facilities for you. We provide laundry, top roof view, restaurant, underground parking, conference hall, 24 hours room service, and much more.
                {/* Semper ac dolor vitae accumsan. Cras interdum hendrerit lacinia. Phasellus accumsan urna vitae molestie interdum. Nam sed placerat libero, non eleifend dolor. */}
                </p>
              {/* <a href="#." className="knsl-btn knsl-btn-md">Services</a> */}
            </div>

          </div>
          <div className="col-lg-4">

            {/* <!-- service card --> */}
            <div className="knsl-ath-card knsl-scroll-animation">
              <div className="knsl-cover-frame">
                <img src="img/services/res.webp" alt="cover" />
              </div>
              <div className="knsl-card-description">
                <h3 className="knsl-mb-10">Restaurant</h3>
                <p>You will find it hard to find another venue as beautiful as SD Prime Hotel </p>
                {/* <a href="#" className="knsl-btn">Read more</a> */}
              </div>
            </div>
            {/* <!-- service card end --> */}

          </div>
          <div className="col-lg-4">

            {/* <!-- service card --> */}
            <div className="knsl-ath-card knsl-scroll-animation">
              <div className="knsl-cover-frame">
                <img src="img/services/topRoof.webp" alt="cover" />
              </div>
              <div className="knsl-card-description">
                <h3 className="knsl-mb-10">Top Roof</h3>
                <p>This place is meant for those people who are looking for a bit of everything for making their evening memorable.</p>
                {/* <a href="#" className="knsl-btn">Read more</a> */}
              </div>
            </div>
            {/* <!-- service card end --> */}

          </div>
          <div className="col-lg-4">

            {/* <!-- service card --> */}
            <div className="knsl-ath-card knsl-scroll-animation">
              <div className="knsl-cover-frame">
                <img src="img/services/7.jpg" alt="cover" />
              </div>
              <div className="knsl-card-description">
                <h3 className="knsl-mb-10">Laundry</h3>
                <p>We refer to the washing of clothing and other textiles, and, more broadly, their drying and ironing as well.</p>
                {/* <a href="#" className="knsl-btn">Read more</a> */}
              </div>
            </div>
            {/* <!-- service card end --> */}

          </div>
          <div className="col-lg-4">

            {/* <!-- service card --> */}
            <div className="knsl-ath-card knsl-scroll-animation">
              <div className="knsl-cover-frame">
                <img src="img/services/t7.webp" alt="cover" />
              </div>
              <div className="knsl-card-description">
                <h3 className="knsl-mb-10">Underground Parking Area</h3>
                <p>Sd Prime has a large space for underground parking.</p>
                {/* <a href="#" className="knsl-btn">Read more</a> */}
              </div>
            </div>
            {/* <!-- service card end --> */}

          </div>
          <div className="col-lg-4">

            {/* <!-- service card --> */}
            <div className="knsl-ath-card knsl-scroll-animation">
              <div className="knsl-cover-frame">
                <img src="img/services/confrence.webp" alt="cover" />
              </div>
              <div className="knsl-card-description">
                <h3 className="knsl-mb-10">Conference Hall</h3>
                <p>When choosing a venue for your next meeting or conference there is more to consider than just the budget.</p>
                {/* <a href="#" className="knsl-btn">Read more</a> */}
              </div>
            </div>
            {/* <!-- service card end --> */}

          </div>
          <div className="col-lg-4">

            {/* <!-- service card --> */}
            <div className="knsl-ath-card knsl-scroll-animation">
              <div className="knsl-cover-frame">
                <img src="img/services/24.webp" alt="cover" />
              </div>
              <div className="knsl-card-description">
                <h3 className="knsl-mb-10">24 Hours Room Service</h3>
                <p>Sd Prime always ready to provide best services.</p>
                {/* <a href="#" className="knsl-btn">Read more</a> */}
              </div>
            </div>
            {/* <!-- service card end --> */}

          </div>
        </div>
      </div>
    </section>
    </div>
  )
}

export default HomeServices