import React, { useState } from 'react'
import CountUp from 'react-countup';

function Counter() {

  const [videoLoad, setvideoLoad] = useState(true)




  const video = "https://firebasestorage.googleapis.com/v0/b/hotel-sd-prime.appspot.com/o/images%2Fgallery%2FInShot_20220411_143427754%20(1).mp4?alt=media&token=6bb21a3d-ffea-42d4-9bfb-6ca6a9b2be58"


  const [loadCount, setLoadCount] = useState(0);

  const setVideoLoaded = () => {
    setvideoLoad(false)
  };




  return (
    <div>
      <section className="knsl-p-0-100" style={{ backgroundColor: "#F5F5F5" }}>
        <img src="img/palm.svg" className="knsl-deco-right" alt="palm" />
        <div className="container">

          {/* <!-- features card --> */}
          <div className="knsl-features-card knsl-counters-card knsl-scroll-animation">

            <div className="row">
              <div className="col-6 col-lg-3">

                {/* <!-- icon box --> */}
                <div className="knsl-icon-box">
                  <div className="knsl-counter-number knsl-mb-10" /* data-count="4" */><CountUp end={17}
                    duration={5} /></div>
                  <h5>Rooms</h5>
                </div>
                {/* <!-- icon box end --> */}

              </div>
              <div className="col-6 col-lg-3">

                {/* <!-- icon box --> */}
                <div className="knsl-icon-box">
                  <div className="knsl-counter-number knsl-mb-10" /* data-count="127" */><CountUp end={4586}
                    duration={5} /></div>
                  <h5>Guests</h5>
                </div>
                {/* <!-- icon box end --> */}

              </div>
              <div className="col-6 col-lg-3">

                {/* <!-- icon box --> */}
                <div className="knsl-icon-box">
                  <div className="knsl-counter-number knsl-mb-10" /* data-count="6" */><CountUp end={45}
                    duration={5} /></div>
                  <h5>Event</h5>
                </div>
                {/* <!-- icon box end --> */}

              </div>
              <div className="col-6 col-lg-3">

                {/* <!-- icon box --> */}
                <div className="knsl-icon-box">
                  <div className="knsl-counter-number knsl-mb-10" /* data-count="4586" */><CountUp end={2}
                    duration={5} /></div>
                  <h5>Restaurant</h5>
                </div>
                {/* <!-- icon box end --> */}

              </div>
            </div>

          </div>
          {/* <!-- features card end --> */}





        </div>
      </section>
    </div>
  )
}

export default Counter