import React, { useState } from 'react'
import { db } from '../../Firebase';
import firebase from "firebase";
import { useHistory } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';

function Banner() {

    const [mynumber, setmynumber] = useState("");
    const [details, setDetails] = useState("");
    const [person, setperson] = useState("1");
    const [childrens, setchildrens] = useState("0");

    const [startDate, setStartDate] = useState(null);
    const [endDate, setendDate] = useState(null);
    const history = useHistory();

    // console.log(startDate < endDate);



    const video = "https://firebasestorage.googleapis.com/v0/b/hotel-sd-prime.appspot.com/o/images%2Fgallery%2FInShot_20220411_143427754%20(1).mp4?alt=media&token=6bb21a3d-ffea-42d4-9bfb-6ca6a9b2be58"


    const hendledetailsAdd = (e) => {

        const { name, value } = e.target;
        setDetails({ ...details, [name]: value });
    };

    const [videoLoad, setvideoLoad] = useState(true)
    const setVideoLoaded = () => {
        setvideoLoad(false)
    };

    const userMainPage = (async () => {

        if (startDate == null) {
            toast.error('Please Select Check In Date', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            document.getElementById('id01').style.display = 'none'

        } else if (endDate === null || startDate >= endDate) {
            toast.error('Please Select Check Out Date', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            document.getElementById('id01').style.display = 'none'

        } else {
            const dones = await db.collection("userEnquiry").add({
                name: details?.name,
                checkInDate: startDate,
                checkOutDate: endDate,
                person: person,
                childrens: childrens,
                phoneNumber: "+" + mynumber,
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            })

            document.getElementById('id01').style.display = 'none'
            // toast.success("Your Enquiry Submited")
            toast.success('Your Enquiry Submited', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }



    })



    const resPop = ((e) => {

        if (e == "show") {

            if (startDate == null) {
                toast.error('Please Select Check In Date', {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else if (endDate == null) {
                toast.error('Please Select Check Out Date', {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });


            } else {

                document.getElementById('id01').style.display = 'block'

            }

        } else {
            document.getElementById('id01').style.display = 'none'
        }



    })


    return (
        <div>


            <section className="knsl-banner">
                <div className="knsl-cover-frame">
                    {/* <video autoPlay muted loop id="myVideo" playsInline>
                        <source src="/img/banners/sdvideo.mp4" type="video/mp4" />
                  
                    </video> */}
                    {videoLoad && <img src="img/banners/88.webp" alt="banner" className="knsl-parallax" />}



                    <video
                        autoPlay
                        playsInline
                        muted
                        className="video"
                        loop
                        src="https://firebasestorage.googleapis.com/v0/b/hotel-sd-prime.appspot.com/o/images%2Fgallery%2FInShot_20220411_143427754%20(1).mp4?alt=media&token=6bb21a3d-ffea-42d4-9bfb-6ca6a9b2be58"
                        onLoadedData={() => {
                            setVideoLoaded();
                        }}
                    />



                </div>
                <div className="knsl-overlay"></div>

                <div className="knsl-banner-content">
                    <div className="container">
                        <div className="knsl-main-title">
                            <div className="knsl-white">
                                <ul className="knsl-stars">
                                    <li><i className="fas fa-star"></i></li>
                                    <li><i className="fas fa-star"></i></li>
                                    <li><i className="fas fa-star"></i></li>
                                    <li><i className="fas fa-star"></i></li>
                                    <li><i className="fas fa-star"></i></li>
                                </ul>
                                {/* <!-- main-title --> */}
                                <h1 className="knsl-center knsl-mb-10">Its Celebrating time<br /> Sprit of Royal Rajasthan</h1>
                                <div className="knsl-mb-30 knsl-center"></div>
                            </div>
                        </div>
                        <div className="knsl-book-form knsl-book-form-2">
                            <form>
                                <div className="row align-items-center">
                                    <div className="col-12 col-lg-3">
                                        <div className="knsl-input-frame knsl-book-form-2 dateIc">
                                            <label htmlFor="check-in" style={{ color: "#ba792b" }}>Check in</label>

                                            <DatePicker
                                                selected={startDate}
                                                onChange={(date) => setStartDate(date)}
                                                // showTimeSelect
                                                // timeFormat="HH:mm:ss"
                                                className="form-control"
                                                minDate={new Date()}
                                                placeholderText="Select date"
                                            // dateFormat="yyyy-MM-dd"
                                            />

                                            {/* <input id="check-in" type="text" className="datepicker-here datep" data-position="bottom left" placeholde   r="Select date" autoComplete="off" readOnly="readOnly" /> */}
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-3">
                                        <div className="knsl-input-frame dateIc">
                                            <label htmlFor="check-out" style={{ color: "#ba792b" }} className="knsl-add-icon">Check out</label>

                                            <DatePicker
                                                selected={endDate}
                                                onChange={(date) => setendDate(date)}
                                                // showTimeSelect
                                                // timeFormat="HH:mm:ss"
                                                className="form-control"
                                                minDate={new Date()}
                                                placeholderText="Select date"
                                            // dateFormat="yyyy-MM-dd"
                                            />



                                            {/* <input id="check-out" type="text" className="datepicker-here datep" name='chackOutDate' onChange={(e)=>{hendledetailsAdd(e)}} data-position="bottom left" placeholder="Select date" autoComplete="off" readOnly="readOnly" /> */}
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-3">
                                        <div className="knsl-select-frame">
                                            <label htmlFor="person" style={{ color: "#ba792b" }}>Person</label>
                                            <select id="person" style={{ display: "none" }}>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4" disabled>4</option>
                                            </select>
                                            <div className="nice-select" tabIndex="0">
                                                <span className="current">1</span>
                                                <ul className="list">
                                                    <li data-value="1" onClick={(e) => { setperson("1") }} className="option selected focus">1</li>
                                                    <li data-value="2" onClick={(e) => { setperson("2") }} className="option">2</li>
                                                    <li data-value="3" onClick={(e) => { setperson("3") }} className="option">3</li>
                                                    <li data-value="4" onClick={(e) => { setperson("4") }} className="option disabled">4</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-2">
                                        <div className="knsl-select-frame">
                                            <label htmlFor="children" style={{ color: "#ba792b" }}>Childrens</label>
                                            <select id="children" style={{ display: "none" }}>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4" disabled>4</option>
                                            </select>

                                            <div className="nice-select" tabIndex="0">
                                                <span className="current" >0</span>
                                                <ul className="list">
                                                    <li data-value="1" onClick={(e) => { setchildrens("1") }} className="option selected">1</li>
                                                    <li data-value="2" className="option" onClick={(e) => { setchildrens("2") }}>2</li>
                                                    <li data-value="3" className="option" onClick={(e) => { setchildrens("3") }}>3</li>
                                                    <li data-value="4" className="option disabled" onClick={(e) => { setchildrens("4") }}>4</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div onClick={(e) => { resPop("show") }}>sdfdsfdsf</div> */}
                                    <div className="col-12 col-lg-1 text-center">
                                        <div /* type="submit" */ className="knsl-btn" onClick={(e) => { resPop("show") }}  /*  data-toggle="modal" data-target="#exampleModal" */><img src="img/icons/search.svg" className="knsl-zoom" alt="icon" /><span>Serch room</span></div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="knsl-scroll-hint-frame">
                            <div className="knsl-white knsl-mb-20">Scroll down</div>
                            <a href="#knsl-triger-1" className="knsl-scroll-hint knsk-s-s"></a>
                        </div>
                    </div>
                </div>
            </section>



            <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Modal title</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <input type={"text"} name="name" onChange={(e) => {
                                hendledetailsAdd(e)
                            }} />


                            <PhoneInput
                                country={"in"}
                                value={mynumber}
                                onChange={setmynumber}
                            />






                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" className={"btn btn-primary"} onClick={(e) => { userMainPage(e) }}>Save changes</button>
                        </div>
                    </div>
                </div>
            </div>







            <div id="id01" className="modal">
                <div className="row">
                    <div className="col-md-6"></div>
                    <div className="col-md-6"></div>
                </div>

                <form className="modal-content resModel animate" >
                    <div className="row">
                        <div className="col-1"></div>
                        {/* <div className="col-md-5">
                                                    <div className="container">

                                                        <div className="imgcontainer">

                                                            <img src="img/png.png" alt="Avatar" className="" />
                                                        </div>
                                                       
                                                    </div>
                                                </div> */}


                        <div className="col-10">




                            <h4 className="login_page">  Your Details  </h4>

                            <input type={"text"} name="name" placeholder='Enter your name' onChange={(e) => {
                                hendledetailsAdd(e)
                            }} />


                            <PhoneInput
                                country={"in"}
                                value={mynumber}
                                onChange={setmynumber}
                            />

                            <div className='enSubBtn'>

                                <button type="button" className={details?.name?.length < 1 ? "btn btn-primary  enSub  disBtn  " : mynumber?.length < 5 ? "btn btn-primary  enSub disBtn " : 'btn btn-primary  enSub'} onClick={(e) => { userMainPage(e) }}>Submit</button>
                            </div>

                        </div>
                        <div className="col-1">
                            <span className="close" onClick={(e) => {
                                resPop("close")
                            }}
                                title="Close Modal">&times;</span>
                        </div>
                    </div>

                </form>
            </div>






        </div>
    )
}

export default Banner