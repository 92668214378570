import React from 'react'

function HomeAbout() {
    return (
        // <div >
        <section className="knsl-p-100-100" id='about'>

            <div className="container">
                <div className="knsl-about-card" >
                    <div className="row no-gutters">
                        <div className="col-lg-6">
                            <div className="knsl-about-photo">
                                <img src="img/about/t7.webp" alt="cover" />
                            </div>
                        </div>
                        <div className="col-lg-6 align-self-center">
                            <div className="knsl-about-text">
                                <div className="knsl-title-frame">
                                    <h2 className="knsl-mb-20">Welcome To  Sd Prime</h2>
                                    <p className="knsl-mb-30">

                                    Sd Prime is the best hotel in Jaipur, situated at a prime location. With the best valet parking service and room service, your stay would be very pleasant and memorable. We provide exclusive accommodation for marriages and functions also. Sd prime hotel has the best facilities like a gym, a spa, restaurants, and a bar for those who want to enjoy the stay with complete comfort. This hotel is only one out of many that provide good quality facilities at budget-friendly prices.

                                        {/* Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. */}
                                    </p>
                                    {/* <a href="#" className="knsl-btn knsl-btn-md">More about us</a> */}
                                </div>
                            </div>
                        </div>
                        {/* <!-- <div className="col-lg-6 order-lg-2">
                                                                            <div className="knsl-about-photo">
                                                                            <img src="img/about/t2.jpg" alt="cover">
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-6 align-self-center">
                                                                            <div className="knsl-about-text">
                                                                            <div className="knsl-title-frame">
                                                                                <h2 className="knsl-mb-20">Start your Amazing Adventure!</h2>
                                                                                <p className="knsl-mb-30">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                                                                <a href="#" className="knsl-btn knsl-btn-md">Choose a room</a>
                                                                            </div>
                                                                            </div>
                                                                        </div> --> */}
                    </div>
                </div>
            </div>

        </section>
        // </div>
    )
}

export default HomeAbout