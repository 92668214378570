import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import PhoneInput from 'react-phone-input-2';
import { toast } from 'react-toastify';
import { db } from '../../Firebase';
import firebase from "firebase";

function HomeContact() {

  const [details, setDetails] = useState({ name: "", message: "" });
  const [mynumber, setmynumber] = useState("");


  const hendledetailsAdd = (e) => {

    const { name, value } = e.target;
    setDetails({ ...details, [name]: value });
  };



  const userMainPage = (async () => {

    if (details?.name?.length < 1) {
      toast.error('Please Select name', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });


      // document.getElementById('id011').style.display = 'none'

    }

    else {
      const dones = await db.collection("contectEnquiry").add({
        name: details?.name,
        phoneNumber: "+" + mynumber,
        message: details?.message,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      })

      document.getElementById('id011').style.display = 'none'
      // toast.success("Your Enquiry Submited")
      toast.success('Your Enquiry Submited', {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }



  })



  const resPop = ((e) => {

    if (e == "show") {

      document.getElementById('id011').style.display = 'block'



    } else {
      document.getElementById('id011').style.display = 'none'
    }



  })








  return (
    <div >
      <section className="knsl-banner-simple knsl-transition-bottom">
        <img src="img/palm.svg" className="knsl-deco-left" alt="palm" />
        <img src="img/palm.svg" className="knsl-deco-right" alt="palm" />
        <div className="container">
          <div className="row">
            <div className="col-lg-12">

              <div className="knsl-center knsl-title-frame">
                <h1 className="knsl-mb-20 knsl-h1-inner">Get in Contact</h1>
                <p className="knsl-mb-30">
                We believe that service is not limited to the four walls of a room. Our team of experts in hospitality provides customized services to make sure your stay with us is the best it can be. Contact us now.
                  {/* Semper ac dolor vitae accumsan. Cras interdum hendrerit lacinia. Phasellus accumsan urna vitae molestie interdum. Nam sed placerat libero, non eleifend dolor. */}
                  </p>
                <ul className="knsl-breadcrumbs">
                  {/* <li><Link to={"/"} >Home</Link>
                  
           
                  </li> */}
                  <li onClick={(e) => {
                    resPop("show")
                  }}><span>Contact</span></li>
                </ul>
              </div>

            </div>
          </div>
        </div>
      </section>

      <section className="knsl-p-100-100" id='contectinfo'>

        <div id="knsl-triger-1" ></div>

        <div className="container" >

          {/* <!-- features card --> */}
          <div className="knsl-features-card">

            <div className="row">
              <div className="col-12 col-lg-4">

                {/* <!-- icon box --> */}
                <div className="knsl-icon-box">
                  <a href="https://goo.gl/maps/cNEwYhAtQVNYpDjx8" target={"_blank"}>
                    <img src="img/features/9.svg" alt="icon" className="knsl-mb-10" />
                    <h5 className="knsl-mb-10">Welcome</h5>
                    <p>HOTEL SD PRIME</p>
                  </a>

                </div>
                {/* <!-- icon box end --> */}

              </div>
              <div className="col-12 col-lg-4">

                {/* <!-- icon box --> */}
                <div className="knsl-icon-box">
                  <img src="img/features/10.svg" alt="icon" className="knsl-mb-10" />
                  <h5 className="knsl-mb-10">Call Us</h5>
                  <p><a href='tel:9983343200'>+91-9983343200</a> </p>
                </div>
                {/* <!-- icon box end --> */}

              </div>
              <div className="col-12 col-lg-4">

                {/* <!-- icon box --> */}
                <div className="knsl-icon-box">
                  <img src="img/features/11.svg" alt="icon" className="knsl-mb-10" />
                  <h5 className="knsl-mb-10">Email Us</h5>
                  <p> <a href="mailto:hotelsdprime@gmail.com">hotelsdprime@gmail.com</a></p>
                  <div className="knsl-footer-social">
                    <a href="#"><i className="fab fa-twitter"></i></a>
                    <a href="#"><i className="fab fa-facebook-f"></i></a>

                    <a href="#"><i className="fab fa-instagram"></i></a>

                  </div>
                </div>
                {/* <!-- icon box end --> */}

              </div>
            </div>

          </div>
          {/* <!-- contact end --> */}

        </div>
      </section>




      <div id="id011" className="modal">
        <div className="row">
          <div className="col-md-6"></div>
          <div className="col-md-6"></div>
        </div>

        <form className="modal-content resModel animate" >
          <div className="row">
            <div className="col-1"></div>
            {/* <div className="col-md-5">
                                                    <div className="container">

                                                        <div className="imgcontainer">

                                                            <img src="img/png.png" alt="Avatar" className="" />
                                                        </div>
                                                       
                                                    </div>
                                                </div> */}


            <div className="col-10">




              <h4 className="login_page">  Your Details  </h4>

              <input type={"text"} name="name" placeholder='Enter your name' onChange={(e) => {
                hendledetailsAdd(e)
              }} />


              <PhoneInput
                country={"in"}
                value={mynumber}
                onChange={setmynumber}
              />



{/* <input type={"text"} name="message" placeholder='Enter  Message' onChange={(e) => {
                hendledetailsAdd(e)
              }} /> */}

              <div className='contq'>
              <textarea type={"text"} name="message" placeholder='Enter  Message' onChange={(e) => {
                hendledetailsAdd(e)
              }}>

              </textarea>
              </div>
            
             
              <div className='enSubBtn'>

                <button type="button" className={details?.name?.length < 1 ? "btn btn-primary  enSub  disBtn  " : mynumber?.length < 5 ? "btn btn-primary  enSub disBtn " : 'btn btn-primary  enSub'} onClick={(e) => { userMainPage(e) }}>Submit</button>
              </div>

            </div>
            <div className="col-1">
              <span className="close" onClick={(e) => {
                resPop("close")
              }}
                title="Close Modal">&times;</span>
            </div>
          </div>

        </form>
      </div>



    </div>
  )
}

export default HomeContact