import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify';
import { auth, db, firebase } from "../../Firebase";
import OTPInput, { ResendOTP } from "otp-input-react";

function Header() {

  const [show, setShow] = useState("0")
  const [showotpBox1, setshowotpBox1] = useState("0")
  const [showotpBox2, setshowotpBox2] = useState("0")
  const [Loginshow, setLoginShow] = useState("0")
  const [registershow, setregistershow] = useState("0")

  const [otp, setOtp] = useState("")
  const [final, setfinal] = useState("");
  const [mynumber, setmynumber] = useState("")
  const [name, setname] = useState("")

  const hendleShow = ((e) => {

    if (show == "0") {
      setShow("1")
    } else {
      setShow("0")
    }


  })




  const signupHendle = (() => {

if (name == "") {
  toast.error("name is required");
}else if (mynumber == "") {
  toast.error("number is required");
}else if ( mynumber.length < 10 ) {
  toast.error("Invalid Mobile Number");
}else {
  db
  .collection('userDetails').where("number", "==", "+91" + mynumber)
  .get()
  .then(snapshot => {

    const userCards = snapshot.docs.map(doc => {
      const userData = doc.data()
      userData.id = doc.id
      return userData
    })


    if (userCards?.length == 1) {

      toast.error("Alredy Register");
      setmynumber("")
      setname("")
    } else {
      signin()
    }
  });
}



  })

  const LoginHendle = (() => {
    db
      .collection('userDetails').where("number", "==", "+91" + mynumber)
      .get()
      .then(snapshot => {

        const userCards = snapshot.docs.map(doc => {
          const userData = doc.data()
          userData.id = doc.id
          return userData
        })


        if (userCards?.length == 1) {
          signin()
        } else {
          toast.error("Your Account not Create Please Register");
          setmynumber("")
        }
      });
  })













  // Sent OTP
  const signin = ((e) => {
    // e.preventdefault()


    // if (mynumber === "" || mynumber.length < 10) return;
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button', {
      'size': 'invisible',
      'callback': (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        // onSignInSubmit();
        // console.log("Recaptca varified")
      },
      defaultCountry: "IN"
    });

    const appVerifier = window.recaptchaVerifier;
    auth
      .signInWithPhoneNumber("+91" + mynumber, appVerifier)

      .then((result) => {
        setfinal(result);
        setshowotpBox1("1")
        setshowotpBox2("1")
        toast.success("otp sent your number");

        // setshow(true);
      })
      .catch((err) => {


        toast.error(err?.message);
      });
  });

  // Validate OTP
  const ValidateOtp = (e) => {
    e.preventDefault()

    if (otp === null || final === null) return;

    final
      .confirm(otp)
      .then(async (result) => {


        localStorage.setItem("user_id", result?.user?.uid)


        const dones = await db.collection("userDetails").add({
          number: result?.user?.phoneNumber,
          name: "lokesh",
          uid: result?.user?.uid,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
        toast.success("Register successfully");
        setshowotpBox1("0")
        setregistershow("0")

        // history.push("/edit-details", { user: id?.state?.userleft })
      })
      .catch((err) => {

        toast.error("erong Otp");
        // alert("Wrong code");
      });
  };


  const LoginValidateOtp = (e) => {
    e.preventDefault()

    if (otp === null || final === null) return;

    final
      .confirm(otp)
      .then(async (result) => {


        localStorage.setItem("user_id", result?.user?.uid)
        toast.success("Login successfully");
        setshowotpBox2("0")
        setLoginShow("0")
        // const dones = await db.collection("userDetails").add({
        //   number: result?.user?.phoneNumber,
        //   name: "lokesh",
        //   uid: result?.user?.uid,
        //   createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        // });



        // history.push("/edit-details", { user: id?.state?.userleft })
      })
      .catch((err) => {

        toast.error("erong Otp");
        // alert("Wrong code");
      });
  };























  return (

    <div className="knsl-top-bar">
      <div className="container">

        <div className="knsl-left-side">
          {/* <!-- logo --> */}

          <Link to={"/"} className="knsl-logo-frame">
            <img src="img/png.png" alt="Kinsley" />
          </Link>

          <div className='contectNum'>

            <a href='tel:+919983343200' ><i class="fa fa-mobile" aria-hidden="true"></i>9983343200</a>
          </div>
          {/* <a href="index.html" className="knsl-logo-frame">
            <img src="img/png.png" alt="Kinsley" />
          </a> */}
          {/* <!-- logo end --> */}
        </div>
        <div className={show == "0" ? "knsl-right-side" : "knsl-right-side knsl-active"} >
          {/* <!-- menu --> */}

          <div className="knsl-menu">
            <nav>
              <ul>
                <li >
                  {/* <a href="#">HOME</a> */}
                  <Link to={"/"} >HOME</Link>
                  {/* <!-- <ul>
                    <li><a href="home-1.html">Home type 1</a></li>
                    <li><a href="home-2.html">Home type 2</a></li>
                    <li><a href="home-3.html">Home type 3</a></li>
                  </ul> --> */}
                </li>
                <li>
                  <a href="https://goo.gl/maps/cNEwYhAtQVNYpDjx8" target={"_blank"}>LOCATION</a>
                </li>
                {/* <li className="menu-item-has-children">
                  <a href="#">GROUPS</a>
                  <!-- <ul>
                    <li><a href="rooms-1.html">Rooms list 1</a></li>
                    <li><a href="rooms-2.html">Rooms list 2</a></li>
                    <li><a href="rooms-1">Room details</a></li>
                  </ul> --> 
                </li> */}
                {/* <li className="menu-item-has-children">
                  <a href="#">DEALS</a>
                 
                </li> */}
                <li>
                  <Link to={"/gallery"}>GALLERY</Link>
                  {/* <a href="#">GALLERY</a> */}
                </li>
                <li className="">
                  <a href="tel:9983343200"><i class="fas fa-phone-volume"></i>9983343200</a>
                  {/* <!-- <ul>
                    <li><a href="blog.html">Blog</a></li>
                    <li><a href="publication.html">Publication type 1</a></li>
                    <li><a href="publication-2.html">Publication type 2</a></li>
                    <li><a href="gallery.html">Gallery</a></li>
                    <li><a href="faq.html">FAQ</a></li>
                    <li><a href="404.html">404</a></li>
                  </ul> --> */}
                </li>
                {/* <li className="menu-item-has-children">
                  <a href="#" id="book-popup"><i className="fa fa-user" style={{ padding: "5px" }}></i><span>LOGIN</span></a>
                
                </li> */}



              { localStorage.getItem("user_id") ? "" :  <li class="">
                  <a href="#" id="book-popup" onClick={(e) => {
                     setLoginShow("1") 
                     setShow("0")
                }}><i class="fa fa-user" style={{ padding: "5px" }}></i><span>LOGIN</span></a>

                </li>}
                { localStorage.getItem("user_id") ? "" :   <li class="">
                  <a href="#" id="book-popup1" onClick={(e) => {
                     setregistershow("1")
                     setShow("0")
                      }}><i class="fa fa-user" style={{ padding: "5px" }}></i><span>REGISTER</span></a>

                </li>}




              </ul>
            </nav>
          </div>
          {/* <!-- menu end -->
          <!-- action button --> */}
          {/* <!-- <a id="book-popup" className="knsl-btn">
            <img src="img/icons/bookmark.svg" alt="icon">Book now</a> -->
          <!-- <div className="follow">
      <div className="knsl-footer-social">
                <a href="#">FOLLOW US:-  <i className="fab fa-twitter"></i></a>
                <a href="#"><i className="fab fa-facebook-f"></i></a>
                <a href="#"><i className="fab fa-instagram"></i></a>
                
              </div>
    </div> -->
          <!-- action button end --> */}
        </div>
        {/* <!-- menu button --> */}
        <div className={show == "0" ? "knsl-menu-btn" : "knsl-menu-btn knsl-active"} onClick={() => { hendleShow() }} ><span></span></div>
        {/* <!-- menu button end --> */}
      </div>


      <div id="knsl-success" class="knsl-popup">
        <img src="img/features/12.svg" alt="success" class="knsl-succes-icon" />
        <h2 class="knsl-mb-20">Success</h2>
        <p>However, message not sent. <br />This pop-up exists for demonstration.</p>
      </div>




      {/*  login */}

      <div class={Loginshow == "1" ? "knsl-popup-frame knsl-active" : "knsl-popup-frame"}>
        <div class="knsl-book-form knsl-book-popup">
          <span class="knsl-close-popup" onClick={(e) => { setLoginShow("0") }}>+</span>
          <div class="login_img">
            <img class="knsl-footer-logo" src="img/png1.png" alt="Hotel SD Prime" />
          </div>
          {/* <form> */}
          {
            showotpBox2 == "0" ?

              <div class="row">
                <div class="col-lg-12">
                  <div class="knsl-input-frame">
                    <label for="number" class="form-label"></label>
                    <input type="number" class="form-control" id="number" value={mynumber} onChange={(e) => { setmynumber(e.target.value) }} placeholder="Mobile Number." />
                  </div>
                </div>
                <div class="col-sm-3 px-4">


                </div>
                <div class="col-sm-6">
                  <a  class="btn btn-dark btnCenter"  onClick={(e) => { LoginHendle(e) }}  >Send OTP</a>

                </div>
                <div class="col-sm-3 px-4">


                </div>
              </div>
              :
              <div class="row">

                <div class="col-sm-2 px-4"></div>
                <div class="col-sm-8 px-4">
                  <OTPInput className="otpInput" value={otp} onChange={setOtp} autoFocus OTPLength={6} otpType="number" disabled={false} secure />

                </div>
                <div class="col-sm-3 px-4"></div>

                <div class="col-lg-12 knsl-center">
                  <a  class="btn btn-dark btnCenter"  onClick={(e) => { LoginValidateOtp(e) }} >Submit</a>
                </div>
              </div>
          }

          {/* </form> */}


          <div>
            <p className='notAc'>You don't have an account <a onClick={(e) => {
              setregistershow("1")
              setLoginShow("0")
            }} >Register</a> </p>
          </div>

        </div>
      </div>




      <div class={registershow == "1" ? "knsl-popup-frame knsl-active" : "knsl-popup-frame"}>
        <div class="knsl-book-form knsl-book-popup">
          <span class="knsl-close-popup" onClick={(e) => { setregistershow("0") }}>+</span>
          <div class="login_img">
            <img class="knsl-footer-logo" src="img/png1.png" alt="Hotel SD Prime" />
          </div>
          {/* <form> */}


          {
            showotpBox1 == "0" ?

              <div class="row">

                <div class="col-lg-12">
                  <div class="knsl-input-frame">
                    <label for="name" class="form-label"></label>
                    <input type="text" class="form-control" id="name" value={name} placeholder="Enter Name" onChange={(e) => { setname(e.target.value) }} />
                  </div>
                </div>
                <div class="col-sm-3 px-4">


                </div>
                <div class="col-lg-12">
                  <div class="knsl-input-frame">
                    <label for="number" class="form-label"></label>
                    <input type="number" class="form-control" id="number" value={mynumber} onChange={(e) => { setmynumber(e.target.value) }} placeholder="Mobile Number." />
                  </div>
                </div>
                <div class="col-sm-3 px-4">


                </div>

                <div class="col-sm-6">
                  <a class="btn btn-dark btnCenter"  onClick={(e) => { signupHendle(e) }} >Send OTP</a>

                </div>
                <div class="col-sm-3 px-4">


                </div>
              </div>

              :
              <div class="row">

                <div class="col-sm-2 px-4"></div>
                <div class="col-sm-8 px-4">
                  <OTPInput className="otpInput" value={otp} onChange={setOtp} autoFocus OTPLength={6} otpType="number" disabled={false} secure />

                </div>
                <div class="col-sm-3 px-4"></div>

                <div class="col-lg-12 knsl-center">
                  <a class="btn btn-dark btnCenter"  onClick={(e) => { ValidateOtp(e) }}>Submit</a>
                </div>
              </div>

          }
          {/* </form> */}

          <div>
            <p className='notAc'>You have an account <a onClick={(e) => {
              setregistershow("0")
              setLoginShow("1")
            }} >Login</a> </p>
          </div>


        </div>
      </div>







      <div id="sign-in-button"></div>

    </div>

  )
}

export default Header