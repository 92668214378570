import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import Slider from "react-slick";
import { db } from '../../Firebase';

function BestRoom() {

  const history = useHistory()

  const [roomDetails, setroomDetails] = useState([])




  useEffect(() => {
    db.collection('roomDetails').orderBy('createdAt', 'desc').onSnapshot(snapshot => {
      const oderdetails = snapshot.docs.map((e) => {
        const data = e.data()
        data.id = e.id
        return data
      });
      setroomDetails(oderdetails)
    })
  }, [])












  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div id='rooms'> <section className="knsl-transition-bottom" style={{ backgroundColor: "#F5F5F5" }}>
      <img src="img/palm.svg" className="knsl-deco-left" alt="palm" />
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-12">

            <div className="knsl-center knsl-title-frame knsl-scroll-animation knsl-mb-100">

              <h2 className="knsl-mb-20">Our Best Rooms</h2>
              <p className="knsl-mb-30">


                SD PRIME is a very comfortable hotel in Jaipur with the best services. In this hotel, room categories are Luxury suites, Deluxe suites, and Executive suites. SD PRIME provides an environment that combines luxury and comfort. We offer you the best Hotel room in Jaipur with the finest facilities to experience a home away from home where memories are made.


                {/* Semper ac dolor vitae accumsan. Cras interdum hendrerit lacinia. Phasellus accumsan urna vitae molestie interdum. Nam sed placerat libero, non eleifend dolor. */}
              </p>
              {/* <a href="#" className="knsl-btn knsl-btn-md">All rooms</a> */}
            </div>

          </div>




          <div className="col-lg-12">

            <div className='bestRoomSlider'>


              <Slider {...settings}>



                {
                  roomDetails && roomDetails?.map((data, i) => {



                    return (
                      <div className='bSlider' key={i}>
                        <div className="knsl-room-card knsl-scroll-animation">
                          <div className="knsl-cover-frame">
                            <a href="#"><img src={data?.image} alt="cover" /></a>
                          </div>
                          <div className="knsl-description-frame">
                            <div className="knsl-room-features">
                              <div className="knsl-feature">
                                <div className="knsl-icon-frame"><img src="img/icons/bed.svg" alt="icon" /></div>
                                <span>{data?.gueste} Guests</span>
                              </div>
                              <div className="knsl-feature">
                                <div className="knsl-icon-frame"><img src="img/icons/square.svg" alt="icon" /></div>
                                <span>{data?.area} Ft²</span>
                              </div>
                            </div>
                            <a href="#">
                              <h3 className="knsl-mb-20">{data?.roomtype}</h3>
                            </a>
                            <div className="knsl-text-light knsl-text-sm knsl-mb-20">
                              {data?.aboutRoom}
                              {/* Libero non aut dignissimos optio hic laudantium ex maiores enim et consequatur corrupti omnis. */}
                            </div>
                            <div className="knsl-card-bottom">
                              <div className="knsl-price">₹{data?.Price}<span>/night</span></div>
                              <a onClick={(e) => {
                                history.push("book-room", { roomId: data?.id })
                              }} className="knsl-btn"><img src="img/icons/bookmark.svg" alt="icon" />Book now</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }



                {/* <div className='bSlider'>
                      <div className="knsl-room-card knsl-scroll-animation">
                        <div className="knsl-cover-frame">
                          <a href="#"><img src="img/rooms/1.jpg" alt="cover" /></a>
                        </div>
                        <div className="knsl-description-frame">
                          <div className="knsl-room-features">
                            <div className="knsl-feature">
                              <div className="knsl-icon-frame"><img src="img/icons/bed.svg" alt="icon" /></div>
                              <span>4 Guests</span>
                            </div>
                            <div className="knsl-feature">
                              <div className="knsl-icon-frame"><img src="img/icons/square.svg" alt="icon" /></div>
                              <span>95 Ft²</span>
                            </div>
                          </div>
                          <a href="#">
                            <h3 className="knsl-mb-20">Deluxe room</h3>
                          </a>
                          <div className="knsl-text-light knsl-text-sm knsl-mb-20">Libero non aut dignissimos optio hic laudantium ex maiores enim et consequatur corrupti omnis.</div>
                          <div className="knsl-card-bottom">
                            <div className="knsl-price">₹175.0<span>/night</span></div>
                            <a href="#" className="knsl-btn"><img src="img/icons/bookmark.svg" alt="icon" />Book now</a>
                          </div>
                        </div>
                      </div>
                    </div> */}
                {/* <div  className='bSlider'>
                      <div className="knsl-room-card knsl-scroll-animation">
                        <div className="knsl-cover-frame">
                          <a href="#"><img src="img/rooms/2.jpg" alt="cover" /></a>
                          <div className="knsl-badge">Popular</div>
                        </div>
                        <div className="knsl-description-frame">
                          <div className="knsl-room-features">
                            <div className="knsl-feature">
                              <div className="knsl-icon-frame"><img src="img/icons/bed.svg" alt="icon" /></div>
                              <span>2 Guests</span>
                            </div>
                            <div className="knsl-feature">
                              <div className="knsl-icon-frame"><img src="img/icons/square.svg" alt="icon" /></div>
                              <span>50 Ft²</span>
                            </div>
                          </div>
                          <a href="#">
                            <h3 className="knsl-mb-20">Standart Room</h3>
                          </a>
                          <div className="knsl-text-light knsl-text-sm knsl-mb-20">Libero non aut dignissimos optio hic laudantium ex maiores enim et consequatur corrupti omnis.</div>
                          <div className="knsl-card-bottom">
                            <div className="knsl-price">₹75.0<span>/night</span></div>
                            <a href="#" className="knsl-btn"><img src="img/icons/bookmark.svg" alt="icon" />Book now</a>
                          </div>
                        </div>
                      </div>
                    </div> */}
                {/* <div  className='bSlider'>
                      <div className="knsl-room-card knsl-scroll-animation">
                        <div className="knsl-cover-frame">
                          <a href="#"><img src="img/rooms/3.jpg" alt="cover" /></a>
                        </div>
                        <div className="knsl-description-frame">
                          <div className="knsl-room-features">
                            <div className="knsl-feature">
                              <div className="knsl-icon-frame"><img src="img/icons/bed.svg" alt="icon" /></div>
                              <span>4 Guests</span>
                            </div>
                            <div className="knsl-feature">
                              <div className="knsl-icon-frame"><img src="img/icons/square.svg" alt="icon" /></div>
                              <span>95 Ft²</span>
                            </div>
                          </div>
                          <a href="#">
                            <h3 className="knsl-mb-20">Deluxe room</h3>
                          </a>
                          <div className="knsl-text-light knsl-text-sm knsl-mb-20">Libero non aut dignissimos optio hic laudantium ex maiores enim et consequatur corrupti omnis.</div>
                          <div className="knsl-card-bottom">
                            <div className="knsl-price">₹175.0<span>/night</span></div>
                            <a href="#" className="knsl-btn"><img src="img/icons/bookmark.svg" alt="icon" />Book now</a>
                          </div>
                        </div>
                      </div>
                    </div> */}
                {/* <div  className='bSlider'>
                      <div className="knsl-room-card knsl-scroll-animation">
                        <div className="knsl-cover-frame">
                          <a href="#"><img src="img/rooms/4.jpg" alt="cover" /></a>
                        </div>
                        <div className="knsl-description-frame">
                          <div className="knsl-room-features">
                            <div className="knsl-feature">
                              <div className="knsl-icon-frame"><img src="img/icons/bed.svg" alt="icon" /></div>
                              <span>1 Guests</span>
                            </div>
                            <div className="knsl-feature">
                              <div className="knsl-icon-frame"><img src="img/icons/square.svg" alt="icon" /></div>
                              <span>35 Ft²</span>
                            </div>
                          </div>
                          <a href="#">
                            <h3 className="knsl-mb-20">Economy room</h3>
                          </a>
                          <div className="knsl-text-light knsl-text-sm knsl-mb-20">Libero non aut dignissimos optio hic laudantium ex maiores enim et consequatur corrupti omnis.</div>
                          <div className="knsl-card-bottom">
                            <div className="knsl-price">₹55.0<span>/night</span></div>
                            <a href="#" className="knsl-btn"><img src="img/icons/bookmark.svg" alt="icon" />Book now</a>
                          </div>
                        </div>
                      </div>
                    </div> */}
                {/* <div  className='bSlider'>
                      <div className="knsl-room-card knsl-scroll-animation">
                        <div className="knsl-cover-frame">
                          <a href="#"><img src="img/rooms/5.jpg" alt="cover" /></a>
                        </div>
                        <div className="knsl-description-frame">
                          <div className="knsl-room-features">
                            <div className="knsl-feature">
                              <div className="knsl-icon-frame"><img src="img/icons/bed.svg" alt="icon" /></div>
                              <span>4 Guests</span>
                            </div>
                            <div className="knsl-feature">
                              <div className="knsl-icon-frame"><img src="img/icons/square.svg" alt="icon" /></div>
                              <span>95 Ft²</span>
                            </div>
                          </div>
                          <a href="#">
                            <h3 className="knsl-mb-20">Deluxe room</h3>
                          </a>
                          <div className="knsl-text-light knsl-text-sm knsl-mb-20">Libero non aut dignissimos optio hic laudantium ex maiores enim et consequatur corrupti omnis.</div>
                          <div className="knsl-card-bottom">
                            <div className="knsl-price">₹175.0<span>/night</span></div>
                            <a href="#" className="knsl-btn"><img src="img/icons/bookmark.svg" alt="icon" />Book now</a>
                          </div>
                        </div>
                      </div>
                    </div> */}
                {/* <div  className='bSlider'>
                      <div className="knsl-room-card knsl-scroll-animation">
                        <div className="knsl-cover-frame">
                          <a href="#"><img src="img/rooms/6.jpg" alt="cover" /></a>
                          <div className="knsl-badge">Popular</div>
                        </div>
                        <div className="knsl-description-frame">
                          <div className="knsl-room-features">
                            <div className="knsl-feature">
                              <div className="knsl-icon-frame"><img src="img/icons/bed.svg" alt="icon" /></div>
                              <span>2 Guests</span>
                            </div>
                            <div className="knsl-feature">
                              <div className="knsl-icon-frame"><img src="img/icons/square.svg" alt="icon" /></div>
                              <span>50 Ft²</span>
                            </div>
                          </div>
                          <a href="#">
                            <h3 className="knsl-mb-20">Standart Room</h3>
                          </a>
                          <div className="knsl-text-light knsl-text-sm knsl-mb-20">Libero non aut dignissimos optio hic laudantium ex maiores enim et consequatur corrupti omnis.</div>
                          <div className="knsl-card-bottom">
                            <div className="knsl-price">₹75.0<span>/night</span></div>
                            <a href="#" className="knsl-btn"><img src="img/icons/bookmark.svg" alt="icon" />Book now</a>
                          </div>

                        </div>
                      </div>
                    </div> */}
              </Slider>
            </div>
            <div className="swiper-container knsl-uni-slider">



              <div className="swiper-wrapper">
                <div className="swiper-slide">




                </div>


                {/* <div className="knsl-room-card knsl-scroll-animation">
                    <div className="knsl-cover-frame">
                      <a href="#"><img src="img/rooms/1.jpg" alt="cover" /></a>
                    </div>
                    <div className="knsl-description-frame">
                      <div className="knsl-room-features">
                        <div className="knsl-feature">
                          <div className="knsl-icon-frame"><img src="img/icons/bed.svg" alt="icon" /></div>
                          <span>4 Guests</span>
                        </div>
                        <div className="knsl-feature">
                          <div className="knsl-icon-frame"><img src="img/icons/square.svg" alt="icon" /></div>
                          <span>95 Ft²</span>
                        </div>
                      </div>
                      <a href="#">
                        <h3 className="knsl-mb-20">Deluxe room</h3>
                      </a>
                      <div className="knsl-text-light knsl-text-sm knsl-mb-20">Libero non aut dignissimos optio hic laudantium ex maiores enim et consequatur corrupti omnis.</div>
                      <div className="knsl-card-bottom">
                        <div className="knsl-price">₹175.0<span>/night</span></div>
                        <a href="#" className="knsl-btn"><img src="img/icons/bookmark.svg" alt="icon" />Book now</a>
                      </div>
                    </div>
                  </div>
                  

                
                <div className="swiper-slide">

              
                  <div className="knsl-room-card knsl-scroll-animation">
                    <div className="knsl-cover-frame">
                      <a href="#"><img src="img/rooms/2.jpg" alt="cover" /></a>
                      <div className="knsl-badge">Popular</div>
                    </div>
                    <div className="knsl-description-frame">
                      <div className="knsl-room-features">
                        <div className="knsl-feature">
                          <div className="knsl-icon-frame"><img src="img/icons/bed.svg" alt="icon" /></div>
                          <span>2 Guests</span>
                        </div>
                        <div className="knsl-feature">
                          <div className="knsl-icon-frame"><img src="img/icons/square.svg" alt="icon" /></div>
                          <span>50 Ft²</span>
                        </div>
                      </div>
                      <a href="#">
                        <h3 className="knsl-mb-20">Standart Room</h3>
                      </a>
                      <div className="knsl-text-light knsl-text-sm knsl-mb-20">Libero non aut dignissimos optio hic laudantium ex maiores enim et consequatur corrupti omnis.</div>
                      <div className="knsl-card-bottom">
                        <div className="knsl-price">₹75.0<span>/night</span></div>
                        <a href="#" className="knsl-btn"><img src="img/icons/bookmark.svg" alt="icon" />Book now</a>
                      </div>
                    </div>
                  </div>
                  

                </div>
                <div className="swiper-slide">

              
                  <div className="knsl-room-card knsl-scroll-animation">
                    <div className="knsl-cover-frame">
                      <a href="#"><img src="img/rooms/3.jpg" alt="cover" /></a>
                    </div>
                    <div className="knsl-description-frame">
                      <div className="knsl-room-features">
                        <div className="knsl-feature">
                          <div className="knsl-icon-frame"><img src="img/icons/bed.svg" alt="icon" /></div>
                          <span>4 Guests</span>
                        </div>
                        <div className="knsl-feature">
                          <div className="knsl-icon-frame"><img src="img/icons/square.svg" alt="icon" /></div>
                          <span>95 Ft²</span>
                        </div>
                      </div>
                      <a href="#">
                        <h3 className="knsl-mb-20">Deluxe room</h3>
                      </a>
                      <div className="knsl-text-light knsl-text-sm knsl-mb-20">Libero non aut dignissimos optio hic laudantium ex maiores enim et consequatur corrupti omnis.</div>
                      <div className="knsl-card-bottom">
                        <div className="knsl-price">₹175.0<span>/night</span></div>
                        <a href="#" className="knsl-btn"><img src="img/icons/bookmark.svg" alt="icon" />Book now</a>
                      </div>
                    </div>
                  </div>
                  

                </div>
                <div className="swiper-slide">

              
                  <div className="knsl-room-card knsl-scroll-animation">
                    <div className="knsl-cover-frame">
                      <a href="#"><img src="img/rooms/4.jpg" alt="cover" /></a>
                    </div>
                    <div className="knsl-description-frame">
                      <div className="knsl-room-features">
                        <div className="knsl-feature">
                          <div className="knsl-icon-frame"><img src="img/icons/bed.svg" alt="icon" /></div>
                          <span>1 Guests</span>
                        </div>
                        <div className="knsl-feature">
                          <div className="knsl-icon-frame"><img src="img/icons/square.svg" alt="icon" /></div>
                          <span>35 Ft²</span>
                        </div>
                      </div>
                      <a href="#">
                        <h3 className="knsl-mb-20">Economy room</h3>
                      </a>
                      <div className="knsl-text-light knsl-text-sm knsl-mb-20">Libero non aut dignissimos optio hic laudantium ex maiores enim et consequatur corrupti omnis.</div>
                      <div className="knsl-card-bottom">
                        <div className="knsl-price">₹55.0<span>/night</span></div>
                        <a href="#" className="knsl-btn"><img src="img/icons/bookmark.svg" alt="icon" />Book now</a>
                      </div>
                    </div>
                  </div>
                  

                </div>
                <div className="swiper-slide">

              
                  <div className="knsl-room-card knsl-scroll-animation">
                    <div className="knsl-cover-frame">
                      <a href="#"><img src="img/rooms/5.jpg" alt="cover" /></a>
                    </div>
                    <div className="knsl-description-frame">
                      <div className="knsl-room-features">
                        <div className="knsl-feature">
                          <div className="knsl-icon-frame"><img src="img/icons/bed.svg" alt="icon" /></div>
                          <span>4 Guests</span>
                        </div>
                        <div className="knsl-feature">
                          <div className="knsl-icon-frame"><img src="img/icons/square.svg" alt="icon" /></div>
                          <span>95 Ft²</span>
                        </div>
                      </div>
                      <a href="#">
                        <h3 className="knsl-mb-20">Deluxe room</h3>
                      </a>
                      <div className="knsl-text-light knsl-text-sm knsl-mb-20">Libero non aut dignissimos optio hic laudantium ex maiores enim et consequatur corrupti omnis.</div>
                      <div className="knsl-card-bottom">
                        <div className="knsl-price">₹175.0<span>/night</span></div>
                        <a href="#" className="knsl-btn"><img src="img/icons/bookmark.svg" alt="icon" />Book now</a>
                      </div>
                    </div>
                  </div>
                  

                </div>
                <div className="swiper-slide">

              
                  <div className="knsl-room-card knsl-scroll-animation">
                    <div className="knsl-cover-frame">
                      <a href="#"><img src="img/rooms/6.jpg" alt="cover" /></a>
                      <div className="knsl-badge">Popular</div>
                    </div>
                    <div className="knsl-description-frame">
                      <div className="knsl-room-features">
                        <div className="knsl-feature">
                          <div className="knsl-icon-frame"><img src="img/icons/bed.svg" alt="icon" /></div>
                          <span>2 Guests</span>
                        </div>
                        <div className="knsl-feature">
                          <div className="knsl-icon-frame"><img src="img/icons/square.svg" alt="icon" /></div>
                          <span>50 Ft²</span>
                        </div>
                      </div>
                      <a href="#">
                        <h3 className="knsl-mb-20">Standart Room</h3>
                      </a>
                      <div className="knsl-text-light knsl-text-sm knsl-mb-20">Libero non aut dignissimos optio hic laudantium ex maiores enim et consequatur corrupti omnis.</div>
                      <div className="knsl-card-bottom">
                        <div className="knsl-price">₹75.0<span>/night</span></div>
                        <a href="#" className="knsl-btn"><img src="img/icons/bookmark.svg" alt="icon" />Book now</a>
                      </div>

                    </div>
                  </div>
                  

                </div>
                <div className="swiper-slide">

              
                  <div className="knsl-room-card knsl-scroll-animation">
                    <div className="knsl-cover-frame">
                      <a href="#"><img src="img/rooms/7.jpg" alt="cover" /></a>
                    </div>
                    <div className="knsl-description-frame">
                      <div className="knsl-room-features">
                        <div className="knsl-feature">
                          <div className="knsl-icon-frame"><img src="img/icons/bed.svg" alt="icon" /></div>
                          <span>1 Guests</span>
                        </div>
                        <div className="knsl-feature">
                          <div className="knsl-icon-frame"><img src="img/icons/square.svg" alt="icon" /></div>
                          <span>35 Ft²</span>
                        </div>
                      </div>
                      <a href="#">
                        <h3 className="knsl-mb-20">Economy room</h3>
                      </a>
                      <div className="knsl-text-light knsl-text-sm knsl-mb-20">Libero non aut dignissimos optio hic laudantium ex maiores enim et consequatur corrupti omnis.</div>
                      <div className="knsl-card-bottom">
                        <div className="knsl-price">₹55.0<span>/night</span></div>
                        <a href="#" className="knsl-btn"><img src="img/icons/bookmark.svg" alt="icon" />Book now</a>
                      </div>
                    </div>
                  </div>
                  

                </div>
                <div className="swiper-slide">

              
                  <div className="knsl-room-card knsl-scroll-animation">
                    <div className="knsl-cover-frame">
                      <a href="#"><img src="img/rooms/8.jpg" alt="cover" /></a>
                    </div>
                    <div className="knsl-description-frame">
                      <div className="knsl-room-features">
                        <div className="knsl-feature">
                          <div className="knsl-icon-frame"><img src="img/icons/bed.svg" alt="icon" /></div>
                          <span>4 Guests</span>
                        </div>
                        <div className="knsl-feature">
                          <div className="knsl-icon-frame"><img src="img/icons/square.svg" alt="icon" /></div>
                          <span>95 Ft²</span>
                        </div>
                      </div>
                      <a href="#">
                        <h3 className="knsl-mb-20">Deluxe room</h3>
                      </a>
                      <div className="knsl-text-light knsl-text-sm knsl-mb-20">Libero non aut dignissimos optio hic laudantium ex maiores enim et consequatur corrupti omnis.</div>
                      <div className="knsl-card-bottom">
                        <div className="knsl-price">₹175.0<span>/night</span></div>
                        <a href="#" className="knsl-btn"><img src="img/icons/bookmark.svg" alt="icon" />Book now</a>
                      </div>
                    </div>
                  </div>
                  

                </div> */}
              </div>

              {/* <!-- slider navigation --> */}
              <div className="knsl-uni-slider-nav-panel">
                <div className=""></div>
                <div className="knsl-uni-nav">
                  <div className="knsl-uni-slider-prev"><i className="fas fa-arrow-left"></i></div>
                  <div className="knsl-uni-slider-next"><i className="fas fa-arrow-right"></i></div>
                </div>
              </div>
              {/* <!-- slider navigation end --> */}

            </div>
          </div>
        </div>
      </div>
    </section>

      <section className="knsl-p-100-100">

        <div className="container">
          <div className="knsl-about-card">
            <div className="row no-gutters">


              <div className="col-lg-6 order-lg-2">
                <div className="knsl-about-photo">
                  <img src="img/about/t6.webp" alt="cover" />
                </div>
              </div>
              <div className="col-lg-6 align-self-center">
                <div className="knsl-about-text">
                  <div className="knsl-title-frame sdAboutLi">
                    {/* <h2 className="knsl-mb-20">HOTEL SD PRIME</h2> */}
                    <h2 className="knsl-mb-20">ABOUT US</h2>
                    {/* <p className="knsl-mb-30">

                      Located in the heart of Jaipur, SD Prime is the best luxury hotel for business and leisure travelers. Our facilities include:
                      ●	A professionally equipped gym.
                      ●	A spa with Indian treatments.
                      ●	An exclusive bar and restaurant to fulfill your appetite and desires.
                      You will find it hard to find another venue as beautiful as SD Prime Hotel for weddings or functions. With our dedicated event planners and luxurious event facilities, your stay will be memorable. The idea of SD Prime Hotel was born after the founders had traveled extensively around India and had found that hotels in many cities were not up to par with international standards. They found themselves constantly commuting between five-star hotels in different cities for work and leisure purposes which made them wish for one comprehensive resort where they could enjoy all the luxuries of premium accommodation without moving from one place to another.


                      The robust, coal-grilled flavours of the North-West Frontier Province are relished here with delicious kababs, breads and lentils.
                    </p> */}
                    <p className="knsl-mb-10">Located in the heart of Jaipur, SD Prime is the best luxury hotel for business and leisure travelers. Our facilities include:</p>
                    <ul>
                      <li>A professionally equipped gym.</li>
                      <li>A spa with Indian treatments.</li>
                      <li>An exclusive bar and restaurant to fulfill your appetite and desires.</li>
                    </ul>
                    <p> You will find it hard to find another venue as beautiful as SD Prime Hotel for weddings or functions. With our dedicated event planners and luxurious event facilities, your stay will be memorable. The idea of SD Prime Hotel was born after the founders had traveled extensively around India and had found that hotels in many cities were not up to par with international standards. They found themselves constantly commuting between five-star hotels in different cities for work and leisure purposes which made them wish for one comprehensive resort where they could enjoy all the luxuries of premium accommodation without moving from one place to another.</p>
                    <p> The robust, coal-grilled flavours of the North-West Frontier Province are relished here with delicious kababs, breads and lentils.</p>
                    {/* <a href="#" className="knsl-btn knsl-btn-md">Explore</a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>



    </div>
  )
}

export default BestRoom